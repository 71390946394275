import React, { useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { FiArrowLeftCircle } from 'react-icons/fi'

import { Container, Content, Section } from './styles'

import Button from '../../components/Button'

import { useAuth } from '../../hooks/auth'

const Settings: React.FC = () => {
  const history = useHistory()
  const { user } = useAuth()

  const init = useCallback(() => {
    if (!user.admin) {
      history.goBack()
    }
  }, [user.admin, history])

  useEffect(() => {
    init()
  }, [init])

  return (
    <Container>
      <Section>
        <FiArrowLeftCircle
          size={40}
          onClick={() => history.goBack()}
          style={{ cursor: 'pointer' }}
        />
        <strong>Configurações</strong>
      </Section>
      <Content>
        <Button onClick={() => history.push('/settings/post')}>
          Exercícios
        </Button>
        <Button onClick={() => history.push('/settings/category')}>
          Categorias
        </Button>
      </Content>
    </Container>
  )
}

export default Settings
