import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.interceptors.response.use(
  response => {
    return response
  },
  err => {
    const res = err.response

    if (res.status === 401) {
      localStorage.removeItem('@GymVideo:token')
      localStorage.removeItem('@GymVideo:user')
      window.location.href = '/'

      return Promise.reject(err.response)
    }

    return Promise.resolve(err.response)
  },
)

export default api
