import styled from 'styled-components'

export const HeaderContainer = styled.header`
  padding: 32px 0;
  background: ${props => props.theme.colors.primary};
`

export const HeaderContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`

export const HeaderLeft = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0.3;

  > img {
    height: 80px;
  }
`

export const HeaderCenter = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 0.3;
`

export const HeaderRight = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex: 0.3;
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: ${props => props.theme.colors.inputColor};
    }

    a {
      text-decoration: none;
      color: #ff9000;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`
