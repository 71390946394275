import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  flex: 0;
`

export const Box = styled.div`
  background: ${props => props.theme.colors.menuItemBackground};
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  margin: 10px;
`

export const Content = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
`

export const Title = styled.span`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  text-align: center;
  padding: 10px;
  word-wrap: break-word;
`
