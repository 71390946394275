import React from 'react'
import { useLocation } from 'react-router-dom'

import { Container, Content, Section } from './styles'

import Header from '../../components/Header'
import List from './List'
import PostDTO from '../../dtos/Post'

interface PostState {
  posts?: PostDTO[]
}

const Posts: React.FC = () => {
  const location = useLocation()

  const { posts } = location.state as PostState

  return (
    <Container>
      <Header />
      <Content>
        <Section>
          <strong>({posts?.length}) Resultados</strong>
          <List items={posts} />
        </Section>
      </Content>
    </Container>
  )
}

export default Posts
