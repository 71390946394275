import React, { useCallback, useRef } from 'react'
import { FiMail, FiArrowLeft } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { useHistory, Link } from 'react-router-dom'

import { useToast } from '../../hooks/toast'

import getValidationErrors from '../../utils/getValidationErrors'

import Input from '../../components/Input'
import Button from '../../components/Button'

import { Container, Content } from './styles'
import { useAuth } from '../../hooks/auth'

interface ProfileFormData {
  email: string
}

const SignOut: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const { addToast } = useToast()
  const history = useHistory()

  const { user, signOut } = useAuth()

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        const { email } = data

        if (!user?.email || user.email === email) {
          signOut()
          history.push('/')

          addToast({
            type: 'success',
            title: 'Logout',
            description: 'Volte sempre!',
          })
        } else {
          addToast({
            type: 'error',
            title: 'Email não confere',
            description: 'Digite o email correto pro usuário da sessão',
          })
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)

          addToast({
            type: 'error',
            title: err.message,
            description: '',
          })

          return
        }

        console.log(err)

        history.push('/')
      }
    },
    [addToast, history, user, signOut],
  )

  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft />
          </Link>
        </div>
      </header>

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h1>Deseja sair ? Digite seu email para confirmar.</h1>

          <Input name="email" icon={FiMail} placeholder="E-mail" />

          <Button type="submit">Sair agora</Button>
        </Form>
      </Content>
    </Container>
  )
}

export default SignOut
