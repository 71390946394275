import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import ReactLoading from 'react-loading'

interface LoadingProps {
  type:
    | 'blank'
    | 'balls'
    | 'bars'
    | 'bubbles'
    | 'cubes'
    | 'cylon'
    | 'spin'
    | 'spinningBubbles'
    | 'spokes'
    | undefined
  height?: number
  width?: number
}

const Loading: React.FC<LoadingProps> = ({ type, height, width }) => {
  const { title } = useContext(ThemeContext)

  return (
    <ReactLoading
      type={type}
      color={title === 'dark' ? '#fff' : '#000'}
      height={height}
      width={width}
    />
  )
}

export default Loading
