import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactElement,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  FiEdit,
  FiArrowLeftCircle,
  FiPlusCircle,
  FiPlay,
  FiMinusCircle,
  FiTag,
} from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'

import Button from '../../../../components/Button'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import Loading from '../../../../components/Loading'

import {
  Container,
  Content,
  Section,
  Subtitle,
  Row,
  VideoContent,
} from './styles'

import getValidationErrors from '../../../../utils/getValidationErrors'

import { useToast } from '../../../../hooks/toast'
import { useAuth } from '../../../../hooks/auth'
import api from '../../../../services/api'

import CategoryDTO from '../../../../dtos/Category'

interface PostData {
  name: string
  description: string
  files: Array<string>
}

const PostAdd: React.FC = () => {
  const [saving, setSaving] = useState<boolean>(false)
  const [countVideo, setCountVideo] = useState<number>(1)
  const [categories, setCategories] = useState<CategoryDTO[]>()
  const history = useHistory()
  const { user } = useAuth()
  const { addToast } = useToast()
  const formRef = useRef<FormHandles>(null)

  const init = useCallback(async () => {
    if (!user.admin) {
      history.goBack()
    }

    const response = await api.get('/categories')

    if (response.status === 200) {
      setCategories(response.data)
    }
  }, [user.admin, history])

  useEffect(() => {
    init()
  }, [init])

  const addVideo = (): void => {
    if (countVideo >= 5) {
      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'É possível adicionar somente 5 vídeos',
      })
      return
    }
    setCountVideo(countVideo + 1)
  }

  const removeVideo = (): void => {
    setCountVideo(countVideo - 1)
  }

  const handleSubmit = useCallback(
    async (data: PostData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatória'),
          description: Yup.string(),
          files: Yup.array().of(
            Yup.lazy(() => Yup.string().required('URL do vídeo obrigatória')),
          ),
          category: Yup.string().required('É necessário informar a categoria'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        setSaving(true)

        const response = await api.post('/posts', data)

        setSaving(false)

        if (response.status === 200) {
          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Exercício salvo com sucesso',
          })

          history.goBack()
        } else {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: response.data.message,
          })
        }
      } catch (err) {
        setSaving(false)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)

          return
        }

        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Houve uma falha ao tentar consultar exercícios',
        })
      }
    },
    [addToast, history],
  )

  const selectPlaceholder = (): ReactElement => (
    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
      <FiTag size={20} />{' '}
      <span style={{ color: 'black', paddingLeft: 15 }}>Categoria</span>
    </div>
  )

  return (
    <Container>
      <Section>
        <FiArrowLeftCircle
          size={40}
          onClick={() => history.goBack()}
          style={{ cursor: 'pointer' }}
        />
        <strong>Adicionar Exercício</strong>
      </Section>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="name" icon={FiEdit} placeholder="Nome" />
          <Input name="description" icon={FiEdit} placeholder="Descrição" />
          <Select
            name="category"
            className="basic-single"
            classNamePrefix="select"
            options={categories}
            getOptionLabel={category => category.name}
            getOptionValue={category => category.id}
            placeholder={selectPlaceholder()}
          />

          <VideoContent>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Subtitle>Vídeos</Subtitle>
            </div>
            {Array.from({ length: countVideo }).map((_, idx) => (
              <Row>
                <div style={{ flex: 0.9 }}>
                  <Input
                    name={`files[${idx}]`}
                    icon={FiPlay}
                    placeholder="URL do Youtube, Vímeo, etc ..."
                  />
                </div>
                <div
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flex: 0.1,
                  }}
                >
                  <FiPlusCircle
                    size={40}
                    onClick={addVideo}
                    style={{ cursor: 'pointer', paddingLeft: 10 }}
                  />
                  {countVideo > 1 && idx + 1 === countVideo && (
                    <FiMinusCircle
                      size={40}
                      onClick={removeVideo}
                      style={{ cursor: 'pointer', paddingLeft: 10 }}
                    />
                  )}
                </div>
              </Row>
            ))}
          </VideoContent>
          {saving ? (
            <Loading type="bubbles" width={100} height={100} />
          ) : (
            <Button type="submit">Salvar</Button>
          )}
        </Form>
      </Content>
    </Container>
  )
}

export default PostAdd
