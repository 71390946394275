import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  padding-top: 40px;
`

export const Content = styled.main`
  flex-direction: column;
  margin: 20px;
`

export const Section = styled.section`
  flex-direction: row;
  align-items: center;
  margin: 20px;
  display: flex;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 30px;
    margin-left: 32px;
  }

  > p {
    color: #999591;
  }
`

export const Description = styled.div`
  flex-direction: row;
  margin-left: 75px;
  display: flex;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 20px;
  }

  > p {
    padding-top: 4px;
    padding-left: 10px;
  }
`

export const Video = styled.div`
  margin: 30px;
`
