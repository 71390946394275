import logoL from '../../assets/logoL.svg'

export default {
  title: 'light',
  logo: `${logoL}`,
  colors: {
    primary: 'linear-gradient(90deg, #FFFFFF 0%, #ff9000 100%)',
    secundary: '#0d0d0d',
    background: '#E5E5E5',
    li: '#ffff',
    text: '#0d0d0d',
    inputColor: '#333',
    inputPlaceholder: '#000000',
    strongSchedule: '#020202',
    appointmentBackground: '#ffff',
    dayPicker: '#fff',
    dayAvailableBackground: '#E5E5E5',
    dayAvailableBackgroundHover: '#efefef',
    menuItemBackground: '#f5a61d',
  },
}
