import React, { useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { FiArrowLeftCircle, FiPower, FiSettings } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../hooks/auth'

import {
  HeaderContainer,
  HeaderContent,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
} from './styles'

const Header: React.FC = () => {
  const { logo } = useContext(ThemeContext)
  const { signOut, user } = useAuth()

  const history = useHistory()

  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderLeft>
          <FiArrowLeftCircle
            size={40}
            onClick={() => history.goBack()}
            style={{ cursor: 'pointer', margin: 20 }}
          />
          <img src={logo} alt="GymVideo" />
        </HeaderLeft>

        <HeaderCenter>
          <span>Bem-vindo, </span>
          <strong>{user.name}</strong>
        </HeaderCenter>

        <HeaderRight>
          {user.admin && (
            <div>
              <button type="button" onClick={() => history.push('/settings')}>
                <FiSettings />
              </button>
            </div>
          )}
          <div>
            <button type="button" onClick={signOut}>
              <FiPower />
            </button>
          </div>
        </HeaderRight>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header
