import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react'
import { ThemeContext } from 'styled-components'
import {
  FiArrowLeftCircle,
  FiArrowRightCircle,
  FiPower,
  FiSettings,
  FiSearch,
  FiPlayCircle,
} from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import Slider from 'react-slick'
import ReactTooltip from 'react-tooltip'

import getValidationErrors from '../../utils/getValidationErrors'

import { useToast } from '../../hooks/toast'

import {
  Container,
  Header,
  HeaderContent,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Content,
  Schedule,
  Section,
  Menu,
  MenuItem,
  MenuItemContent,
  MenuItemTitle,
} from './styles'

import Input from '../../components/Input'
import Button from '../../components/Button'
import Loading from '../../components/Loading'

import { useAuth } from '../../hooks/auth'
import api from '../../services/api'

/*  import CategoryDTO from '../../dtos/Category' */
import PostDTO from '../../dtos/Post'

interface SearchData {
  search: string
}

const Dashboard: React.FC = () => {
  const { logo, colors } = useContext(ThemeContext)

  /*  const [categories, setCategories] = useState<CategoryDTO[]>([]) */
  const [loading, setLoading] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [posts, setPosts] = useState<PostDTO[]>([])

  const { user } = useAuth()
  const { addToast } = useToast()
  const history = useHistory()

  const formRef = useRef<FormHandles>(null)

  const init = useCallback(() => {
    /*
    api.get(`/categories`).then(response => {
      setCategories(response.data)
    })
    */

    setLoading(true)

    api
      .get(`/posts?relations=false&limit=10`)
      .then(response => {
        setPosts(response.data)

        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        addToast({
          type: 'error',
          title: 'Falha ao comunicar com a API!',
          description: 'Contate o desenvolvedor.',
        })
      })
  }, [addToast])

  useEffect(() => {
    init()
  }, [init])

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: posts.length < 6 ? 1 : 6,
    slidesToScroll: posts.length < 6 ? 1 : 6,
    nextArrow: <FiArrowRightCircle color={colors.text} />,
    prevArrow: <FiArrowLeftCircle color={colors.text} />,
  }

  const handleSubmit = useCallback(
    async (data: SearchData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          search: Yup.string().required('Busca obrigatória'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        setSearching(true)

        const response = await api.get(`/posts/search/${data.search}`)

        setSearching(false)

        if (response.data?.length > 1) {
          history.push({
            pathname: '/posts',
            state: { posts: response.data },
          })
        } else if (response.data?.length) {
          history.push({
            pathname: `/post/${response.data[0].id}`,
          })
        } else {
          addToast({
            type: 'error',
            title: 'Exercício não foi encontrado!',
            description: 'Confira o nome do exercício e tente novamente',
          })
        }
      } catch (err) {
        setSearching(false)

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)

          return
        }

        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Houve uma falha ao tentar consultar exercícios',
        })
      }
    },
    [addToast, history],
  )

  /*
  const findByCategory = useCallback(
    async category => {
      try {
        const response = await api.get(`/categories/${category.id}/posts`)

        if (response.data?.length) {
          history.push({
            pathname: '/posts',
            state: { posts: response.data },
          })
        } else {
          addToast({
            type: 'error',
            title: 'Ops!',
            description: `Nenhum exercício foi encontrado para a categoria ${category.name}`,
          })
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err)

          formRef.current?.setErrors(errors)

          return
        }

        addToast({
          type: 'error',
          title: 'Ops!',
          description: 'Houve uma falha ao tentar consultar exercícios',
        })
      }
    },
    [addToast, history],
  )
  */

  /*
  function renderCategories(): ReactElement {
    return (
      <Section>
        <strong>Categorias</strong>
        <Menu>
          <Slider {...sliderSettings}>
            {categories.map((category: CategoryDTO) => (
              <MenuItem
                key={category.id}
                onClick={() => findByCategory(category)}
              >
                <MenuItemContent>
                  <FiTag size={35} />
                  <MenuItemTitle>
                    {category.name.length > 20
                      ? `${category.name.substr(0, 20)} ...`
                      : category.name}
                  </MenuItemTitle>
                </MenuItemContent>
              </MenuItem>
            ))}
          </Slider>
        </Menu>
        {!categories.length && <span>Nenhuma categoria cadastrado ainda</span>}
      </Section>
    )
  }
  */

  return (
    <Container>
      <Header>
        <HeaderContent>
          <HeaderLeft>
            <img src={logo} alt="GymVideo" />
          </HeaderLeft>

          <HeaderCenter>
            <span>Bem-vindo, </span>
            <strong>{user.name}!</strong>
          </HeaderCenter>

          <HeaderRight>
            <div>
              {user.admin && (
                <button
                  type="button"
                  onClick={() => history.push('/settings/post')}
                >
                  <FiSettings />
                </button>
              )}
            </div>

            <div>
              <button type="button" onClick={() => history.push('/signout')}>
                <FiPower />
              </button>
            </div>
          </HeaderRight>
        </HeaderContent>
      </Header>
      <Content>
        <Schedule>
          <Section>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="search"
                icon={FiSearch}
                placeholder="Procurar Exercício..."
                style={{
                  fontSize: '35pt',
                  textAlign: 'center',
                  caretColor: colors.menuItemBackground,
                }}
                autoFocus={!!true}
              />
              {searching ? (
                <div
                  style={{
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <Loading type="bubbles" height={100} width={100} />
                </div>
              ) : (
                <Button type="submit">Procurar</Button>
              )}
            </Form>
          </Section>
          <Section>
            <strong>Últimos Exercícios</strong>
            <Menu>
              {loading ? (
                <Loading type="bubbles" height={100} width={100} />
              ) : (
                <Slider {...sliderSettings}>
                  {posts.map((post: PostDTO) => (
                    <MenuItem
                      key={post.id}
                      onClick={() => history.push(`/post/${post.id}`)}
                    >
                      <MenuItemContent>
                        <FiPlayCircle size={35} />
                        <MenuItemTitle data-tip={post.name}>
                          {post.name.length > 20
                            ? `${post.name.substr(0, 20)} ...`
                            : post.name}
                        </MenuItemTitle>
                        <ReactTooltip
                          effect="solid"
                          backgroundColor={colors.text}
                          textColor={colors.background}
                          borderColor="#fff"
                        />
                      </MenuItemContent>
                    </MenuItem>
                  ))}
                </Slider>
              )}
            </Menu>
            {!posts.length && <span>Nenhum exercício cadastrado ainda</span>}
          </Section>
        </Schedule>
      </Content>
    </Container>
  )
}

export default Dashboard
