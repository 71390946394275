import React, {
  useState,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  ReactElement,
} from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import {
  FiEdit,
  FiTrash,
  FiPlusCircle,
  FiArrowLeftCircle,
} from 'react-icons/fi'
import DataTable, { createTheme } from 'react-data-table-component'
import Loading from '../../../components/Loading'

import PostDTO from '../../../dtos/Post'

import { Container, Content, Header, HeaderLeft } from './styles'

import { useAuth } from '../../../hooks/auth'
import { useToast } from '../../../hooks/toast'

import api from '../../../services/api'

const PostSettings: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [posts, setPosts] = useState<PostDTO[]>([])
  const { colors } = useContext(ThemeContext)
  const history = useHistory()
  const { user } = useAuth()
  const { addToast } = useToast()

  const init = useCallback(async () => {
    if (!user.admin) {
      history.goBack()
    }

    try {
      setLoading(true)

      const response = await api.get('/posts')
      setPosts(response.data)

      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
      addToast({
        type: 'error',
        title: 'Falha ao comunicar com a API',
        description: 'Contate o desenvolvedor',
      })
    }
  }, [user.admin, history, addToast])

  useEffect(() => {
    init()
  }, [init])

  const edit = useCallback(
    post => {
      history.push({
        pathname: `/settings/post/${post.id}`,
        state: { post },
      })
    },
    [history],
  )

  const destroy = useCallback(
    async post => {
      if (!window.confirm(`Apagar ${post.name} ?`)) {
        return
      }

      const response = await api.delete(`/posts/${post.id}`)

      if (response.status === 200) {
        setPosts(posts.filter(p => p.id !== post.id))
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Exercício apagado',
        })
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: response.data.message,
        })
      }
    },
    [posts, addToast],
  )

  createTheme('solarized', {
    text: {
      primary: colors.primary,
      secondary: colors.secundary,
    },
    background: {
      default: colors.background,
    },
    context: {
      background: colors.background,
      text: colors.text,
    },
    divider: {
      default: colors.text,
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  })

  const columns = useMemo(
    () => [
      {
        name: 'Nome',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'Descrição',
        selector: 'description',
      },
      {
        name: 'Categoria',
        selector: 'category.name',
      },
      {
        name: 'Ação',
        cell: (row: PostDTO) => (
          <>
            <FiEdit
              size={30}
              color="orange"
              onClick={() => edit(row)}
              style={{ cursor: 'pointer' }}
            />
            <FiTrash
              size={30}
              color="red"
              onClick={() => destroy(row)}
              style={{ cursor: 'pointer' }}
            />
          </>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [edit, destroy],
  )

  function renderContent(): ReactElement {
    if (loading) {
      return <Loading type="bubbles" width={100} height={100} />
    }

    if (!posts.length) {
      return <span>Nenhum exercício cadastrado no momento</span>
    }
    return (
      <DataTable
        title={null}
        columns={columns}
        data={posts}
        theme="solarized"
      />
    )
  }

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <FiArrowLeftCircle
            size={40}
            onClick={() => history.goBack()}
            style={{ cursor: 'pointer' }}
          />
          <strong>Exercícios</strong>
        </HeaderLeft>
        <div>
          <FiPlusCircle
            size={40}
            onClick={() => history.push('/settings/post/add')}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </Header>
      <Content>{renderContent()}</Content>
    </Container>
  )
}

export default PostSettings
