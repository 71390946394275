import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi'
import { ThemeContext } from 'styled-components'
import Slider from 'react-slick'

import PostDTO from '../../dtos/Post'
import FileDTO from '../../dtos/File'

import useWindow from '../../hooks/window'

import api from '../../services/api'

import { Container, Content, Section, Description, Video } from './styles'

const Post: React.FC = () => {
  const { colors } = useContext(ThemeContext)
  const { id } = useParams()
  const history = useHistory()
  const { height } = useWindow()
  const [post, setPost] = useState<PostDTO>()

  const init = useCallback(async () => {
    const response = await api.get(`/posts/${id}`)
    setPost(response.data)
  }, [id])

  useEffect(() => {
    init()
  }, [init])

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <FiArrowRightCircle color={colors.text} />,
    prevArrow: <FiArrowLeftCircle color={colors.text} />,
  }

  return (
    <Container>
      <Section>
        <FiArrowLeftCircle
          size={40}
          onClick={() => history.goBack()}
          style={{ cursor: 'pointer' }}
        />
        <strong>{post?.name}</strong>
      </Section>
      <Content>
        {post?.description && (
          <Description>
            <strong>Descrição: </strong>
            <p>{post.description}</p>
          </Description>
        )}
        <Video>
          <Slider {...sliderSettings}>
            {post?.files &&
              post.files.map((file: FileDTO) => (
                <iframe
                  title={file.name}
                  key={file.id}
                  width="100%"
                  height={height / 1.35}
                  src={file.file_url}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              ))}
          </Slider>
        </Video>
      </Content>
    </Container>
  )
}

export default Post
