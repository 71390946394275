import styled from 'styled-components'

export const Container = styled.div``

export const Header = styled.header`
  padding: 32px 0;
  background: ${props => props.theme.colors.primary};
`

export const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  button {
    margin-left: auto;
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`

export const HeaderLeft = styled.div`
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  flex: 0.3;

  > img {
    height: 80px;
  }
`

export const HeaderCenter = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 0.3;
`

export const HeaderRight = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  flex: 0.3;
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: ${props => props.theme.colors.inputColor};
    }

    a {
      text-decoration: none;
      color: #ff9000;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`
export const Content = styled.div`
  max-width: 1100px;
  margin: 64px auto;
  flex: 1;
`

export const Schedule = styled.div`
  h1 {
    font-size: 36px;
  }

  p {
    margin-top: 8px;
    color: #ff9000;
    display: flex;
    align-items: center;
    font-weight: 500;

    span {
      display: flex;
      align-items: center;
    }

    span + span::before {
      content: '';
      width: 1px;
      height: 12px;
      background: #ff9000;
      margin: 0 8px;
    }
  }
`

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`
export const Menu = styled.div`
  flex-direction: row;
`

export const MenuItem = styled.div`
  background: ${props => props.theme.colors.menuItemBackground};
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  margin-right: 20px;
`

export const MenuItemContent = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  flex: 1;
`

export const MenuItemTitle = styled.span`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  text-align: center;
  padding: 10px;
  word-wrap: break-word;
`
