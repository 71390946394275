import React from 'react'
import { FiPlayCircle } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { Container, Box, Content, Title } from './styles'

import PostDTO from '../../../dtos/Post'

interface Props {
  items: PostDTO[] | undefined
}

const List: React.FC<Props> = ({ items }) => {
  const history = useHistory()

  const onClick = (item: PostDTO): void => {
    history.push(`/post/${item.id}`)
  }

  return (
    <Container>
      {items &&
        items.map((item: PostDTO) => (
          <Box key={item.id} onClick={() => onClick(item)}>
            <Content>
              <FiPlayCircle size={35} />
              <Title>
                {item.name.length > 20
                  ? `${item.name.substr(0, 20)} ...`
                  : item.name}
              </Title>
            </Content>
          </Box>
        ))}
    </Container>
  )
}

export default List
