import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  padding-top: 40px;
`

export const Content = styled.div`
  flex-direction: column;
  display: flex;
  margin: 60px;
  flex: 1;
`

export const Section = styled.section`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  display: flex;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 30px;
    margin-left: 32px;
  }
`

export const Header = styled.section`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px;
  display: flex;
`

export const HeaderLeft = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 30px;
    margin-left: 32px;
  }
`
