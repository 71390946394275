import styled from 'styled-components'

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding-top: 40px;
`

export const Content = styled.div`
  margin: 60px;
  flex: 1;
`

export const Section = styled.section`
  flex-direction: row;
  align-items: center;
  margin: 20px;
  display: flex;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 30px;
    margin-left: 32px;
  }
`

export const Subtitle = styled.strong`
  color: ${props => props.theme.colors.strongSchedule};
  font-size: 25px;
`

export const VideoContent = styled.div`
  flex-direction: column;
  margin: 50px;
`

export const Row = styled.div`
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
  flex: 1;
`
