import styled from 'styled-components'

export const Container = styled.div``

export const Content = styled.main`
  margin: 20px;
  display: flex;
  flex: 1;
`

export const Section = styled.section`
  flex: 1;
  flex-grow: 1;

  > strong {
    color: ${props => props.theme.colors.strongSchedule};
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`
