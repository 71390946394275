import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

import SignIn from '../pages/SignIn'
import SignUp from '../pages/SignUp'
import SignOut from '../pages/SignOut'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Profile from '../pages/Profile'

import Dashboard from '../pages/Dashboard'

import Category from '../pages/Category'

import Posts from '../pages/Posts'
import Post from '../pages/Post'

import Settings from '../pages/Settings'

import PostSettings from '../pages/Settings/Post'
import PostAdd from '../pages/Settings/Post/Add'
import PostEdit from '../pages/Settings/Post/Edit'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/signout" component={SignOut} isPrivate />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/category" component={Category} isPrivate />
      <Route path="/posts" component={Posts} isPrivate />
      <Route path="/post/:id" component={Post} isPrivate />

      <Route path="/settings" component={Settings} exact isPrivate />
      <Route path="/settings/post" component={PostSettings} exact isPrivate />
      <Route path="/settings/post/add" component={PostAdd} exact isPrivate />
      <Route path="/settings/post/:id" component={PostEdit} exact isPrivate />
    </Switch>
  )
}

export default Routes
