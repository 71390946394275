import { useState, useEffect } from 'react'

interface WindowDimensionsDTO {
  width: number
  height: number
}

function getWindowDimensions(): WindowDimensionsDTO {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions(): WindowDimensionsDTO {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensionsDTO>(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
